body,
span,
input,
label,
button,
li,
a,
p {
  color: black;
}
